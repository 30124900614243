import clsx from "clsx";
import { getIn, useFormikContext } from "formik";

type Props = {
  open?: boolean;
  disabled?: boolean;
  onToggle(state: boolean);
  size?: "base" | "lg";
};

const SwitchButton = ({ open, onToggle, disabled, size = "base" }: Props) => {
  return (
    <button
      className={clsx(
        "border bg-gray-200 dark:border-gray-600 w-7",
        "rounded-full cursor-pointer",
        "appearance-none p-[1px] transition-all duration-150",
        { "w-[2.95rem] p-[2px]": size === "lg" },
        {
          "border-green-200 bg-green-100 dark:bg-green-800 dark:border-green-500":
            open,
        },
        { "bg-white dark:bg-gray-800": !open },
        { "opacity-60": disabled }
      )}
      type="button"
      onClick={() => {
        if (disabled) return;
        onToggle(!open);
      }}
    >
      <div
        className={clsx(
          "w-3 h-3 rounded-full transition-all duration-150",
          { "w-[1.3rem] h-[1.3rem]": size === "lg" },
          { "bg-green-500 translate-x-3": open },
          { "translate-x-5": open && size === "lg" },
          { "bg-gray-500": !open }
        )}
      />
    </button>
  );
};

export const FieldSwitchButton = ({ name = "", ...props }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <SwitchButton
      {...props}
      open={!!getIn(values, name)}
      onToggle={(value) => setFieldValue(name, value)}
    />
  );
};

export default SwitchButton;
