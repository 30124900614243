const QRCodeDefault2 = () => {
  return (
    <svg viewBox="0 0 301 301" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1892 0.661133H0.189209V12.6611H12.1892V0.661133Z"
        fill="black"
      />
      <path
        d="M12.1892 12.6611H0.189209V24.6611H12.1892V12.6611Z"
        fill="black"
      />
      <path
        d="M12.1892 24.6611H0.189209V36.6611H12.1892V24.6611Z"
        fill="black"
      />
      <path
        d="M12.1892 36.6611H0.189209V48.6611H12.1892V36.6611Z"
        fill="black"
      />
      <path
        d="M12.1892 48.6611H0.189209V60.6611H12.1892V48.6611Z"
        fill="black"
      />
      <path
        d="M12.1892 60.6611H0.189209V72.6611H12.1892V60.6611Z"
        fill="black"
      />
      <path
        d="M12.1892 72.6611H0.189209V84.6611H12.1892V72.6611Z"
        fill="black"
      />
      <path
        d="M12.1892 216.661H0.189209V228.661H12.1892V216.661Z"
        fill="black"
      />
      <path
        d="M12.1892 228.661H0.189209V240.661H12.1892V228.661Z"
        fill="black"
      />
      <path
        d="M12.1892 240.661H0.189209V252.661H12.1892V240.661Z"
        fill="black"
      />
      <path
        d="M12.1892 252.661H0.189209V264.661H12.1892V252.661Z"
        fill="black"
      />
      <path
        d="M12.1892 264.661H0.189209V276.661H12.1892V264.661Z"
        fill="black"
      />
      <path
        d="M12.1892 276.661H0.189209V288.661H12.1892V276.661Z"
        fill="black"
      />
      <path
        d="M12.1892 288.661H0.189209V300.661H12.1892V288.661Z"
        fill="black"
      />
      <path
        d="M24.1892 0.661133H12.1892V12.6611H24.1892V0.661133Z"
        fill="black"
      />
      <path
        d="M24.1892 72.6611H12.1892V84.6611H24.1892V72.6611Z"
        fill="black"
      />
      <path
        d="M24.1892 108.661H12.1892V120.661H24.1892V108.661Z"
        fill="black"
      />
      <path
        d="M24.1892 156.661H12.1892V168.661H24.1892V156.661Z"
        fill="black"
      />
      <path
        d="M24.1892 180.661H12.1892V192.661H24.1892V180.661Z"
        fill="black"
      />
      <path
        d="M24.1892 192.661H12.1892V204.661H24.1892V192.661Z"
        fill="black"
      />
      <path
        d="M24.1892 216.661H12.1892V228.661H24.1892V216.661Z"
        fill="black"
      />
      <path
        d="M24.1892 288.661H12.1892V300.661H24.1892V288.661Z"
        fill="black"
      />
      <path
        d="M36.1892 0.661133H24.1892V12.6611H36.1892V0.661133Z"
        fill="black"
      />
      <path
        d="M36.1892 24.6611H24.1892V36.6611H36.1892V24.6611Z"
        fill="black"
      />
      <path
        d="M36.1892 36.6611H24.1892V48.6611H36.1892V36.6611Z"
        fill="black"
      />
      <path
        d="M36.1892 48.6611H24.1892V60.6611H36.1892V48.6611Z"
        fill="black"
      />
      <path
        d="M36.1892 72.6611H24.1892V84.6611H36.1892V72.6611Z"
        fill="black"
      />
      <path
        d="M36.1892 120.661H24.1892V132.661H36.1892V120.661Z"
        fill="black"
      />
      <path
        d="M36.1892 132.661H24.1892V144.661H36.1892V132.661Z"
        fill="black"
      />
      <path
        d="M36.1892 144.661H24.1892V156.661H36.1892V144.661Z"
        fill="black"
      />
      <path
        d="M36.1892 168.661H24.1892V180.661H36.1892V168.661Z"
        fill="black"
      />
      <path
        d="M36.1892 192.661H24.1892V204.661H36.1892V192.661Z"
        fill="black"
      />
      <path
        d="M36.1892 216.661H24.1892V228.661H36.1892V216.661Z"
        fill="black"
      />
      <path
        d="M36.1892 240.661H24.1892V252.661H36.1892V240.661Z"
        fill="black"
      />
      <path
        d="M36.1892 252.661H24.1892V264.661H36.1892V252.661Z"
        fill="black"
      />
      <path
        d="M36.1892 264.661H24.1892V276.661H36.1892V264.661Z"
        fill="black"
      />
      <path
        d="M36.1892 288.661H24.1892V300.661H36.1892V288.661Z"
        fill="black"
      />
      <path
        d="M48.1892 0.661133H36.1892V12.6611H48.1892V0.661133Z"
        fill="black"
      />
      <path
        d="M48.1892 24.6611H36.1892V36.6611H48.1892V24.6611Z"
        fill="black"
      />
      <path
        d="M48.1892 36.6611H36.1892V48.6611H48.1892V36.6611Z"
        fill="black"
      />
      <path
        d="M48.1892 48.6611H36.1892V60.6611H48.1892V48.6611Z"
        fill="black"
      />
      <path
        d="M48.1892 72.6611H36.1892V84.6611H48.1892V72.6611Z"
        fill="black"
      />
      <path
        d="M48.1892 96.6611H36.1892V108.661H48.1892V96.6611Z"
        fill="black"
      />
      <path
        d="M48.1892 108.661H36.1892V120.661H48.1892V108.661Z"
        fill="black"
      />
      <path
        d="M48.1892 156.661H36.1892V168.661H48.1892V156.661Z"
        fill="black"
      />
      <path
        d="M48.1892 216.661H36.1892V228.661H48.1892V216.661Z"
        fill="black"
      />
      <path
        d="M48.1892 240.661H36.1892V252.661H48.1892V240.661Z"
        fill="black"
      />
      <path
        d="M48.1892 252.661H36.1892V264.661H48.1892V252.661Z"
        fill="black"
      />
      <path
        d="M48.1892 264.661H36.1892V276.661H48.1892V264.661Z"
        fill="black"
      />
      <path
        d="M48.1892 288.661H36.1892V300.661H48.1892V288.661Z"
        fill="black"
      />
      <path
        d="M60.1892 0.661133H48.1892V12.6611H60.1892V0.661133Z"
        fill="black"
      />
      <path
        d="M60.1892 24.6611H48.1892V36.6611H60.1892V24.6611Z"
        fill="black"
      />
      <path
        d="M60.1892 36.6611H48.1892V48.6611H60.1892V36.6611Z"
        fill="black"
      />
      <path
        d="M60.1892 48.6611H48.1892V60.6611H60.1892V48.6611Z"
        fill="black"
      />
      <path
        d="M60.1892 72.6611H48.1892V84.6611H60.1892V72.6611Z"
        fill="black"
      />
      <path
        d="M60.1892 96.6611H48.1892V108.661H60.1892V96.6611Z"
        fill="black"
      />
      <path
        d="M60.1892 108.661H48.1892V120.661H60.1892V108.661Z"
        fill="black"
      />
      <path
        d="M60.1892 120.661H48.1892V132.661H60.1892V120.661Z"
        fill="black"
      />
      <path
        d="M60.1892 168.661H48.1892V180.661H60.1892V168.661Z"
        fill="black"
      />
      <path
        d="M60.1892 192.661H48.1892V204.661H60.1892V192.661Z"
        fill="black"
      />
      <path
        d="M60.1892 216.661H48.1892V228.661H60.1892V216.661Z"
        fill="black"
      />
      <path
        d="M60.1892 240.661H48.1892V252.661H60.1892V240.661Z"
        fill="black"
      />
      <path
        d="M60.1892 252.661H48.1892V264.661H60.1892V252.661Z"
        fill="black"
      />
      <path
        d="M60.1892 264.661H48.1892V276.661H60.1892V264.661Z"
        fill="black"
      />
      <path
        d="M60.1892 288.661H48.1892V300.661H60.1892V288.661Z"
        fill="black"
      />
      <path
        d="M72.1892 0.661133H60.1892V12.6611H72.1892V0.661133Z"
        fill="black"
      />
      <path
        d="M72.1892 72.6611H60.1892V84.6611H72.1892V72.6611Z"
        fill="black"
      />
      <path
        d="M72.1892 144.661H60.1892V156.661H72.1892V144.661Z"
        fill="black"
      />
      <path
        d="M72.1892 192.661H60.1892V204.661H72.1892V192.661Z"
        fill="black"
      />
      <path
        d="M72.1892 216.661H60.1892V228.661H72.1892V216.661Z"
        fill="black"
      />
      <path
        d="M72.1892 288.661H60.1892V300.661H72.1892V288.661Z"
        fill="black"
      />
      <path
        d="M84.1892 0.661133H72.1892V12.6611H84.1892V0.661133Z"
        fill="black"
      />
      <path
        d="M84.1892 12.6611H72.1892V24.6611H84.1892V12.6611Z"
        fill="black"
      />
      <path
        d="M84.1892 24.6611H72.1892V36.6611H84.1892V24.6611Z"
        fill="black"
      />
      <path
        d="M84.1892 36.6611H72.1892V48.6611H84.1892V36.6611Z"
        fill="black"
      />
      <path
        d="M84.1892 48.6611H72.1892V60.6611H84.1892V48.6611Z"
        fill="black"
      />
      <path
        d="M84.1892 60.6611H72.1892V72.6611H84.1892V60.6611Z"
        fill="black"
      />
      <path
        d="M84.1892 72.6611H72.1892V84.6611H84.1892V72.6611Z"
        fill="black"
      />
      <path
        d="M84.1892 96.6611H72.1892V108.661H84.1892V96.6611Z"
        fill="black"
      />
      <path
        d="M84.1892 120.661H72.1892V132.661H84.1892V120.661Z"
        fill="black"
      />
      <path
        d="M84.1892 144.661H72.1892V156.661H84.1892V144.661Z"
        fill="black"
      />
      <path
        d="M84.1892 168.661H72.1892V180.661H84.1892V168.661Z"
        fill="black"
      />
      <path
        d="M84.1892 192.661H72.1892V204.661H84.1892V192.661Z"
        fill="black"
      />
      <path
        d="M84.1892 216.661H72.1892V228.661H84.1892V216.661Z"
        fill="black"
      />
      <path
        d="M84.1892 228.661H72.1892V240.661H84.1892V228.661Z"
        fill="black"
      />
      <path
        d="M84.1892 240.661H72.1892V252.661H84.1892V240.661Z"
        fill="black"
      />
      <path
        d="M84.1892 252.661H72.1892V264.661H84.1892V252.661Z"
        fill="black"
      />
      <path
        d="M84.1892 264.661H72.1892V276.661H84.1892V264.661Z"
        fill="black"
      />
      <path
        d="M84.1892 276.661H72.1892V288.661H84.1892V276.661Z"
        fill="black"
      />
      <path
        d="M84.1892 288.661H72.1892V300.661H84.1892V288.661Z"
        fill="black"
      />
      <path
        d="M96.1892 96.6611H84.1892V108.661H96.1892V96.6611Z"
        fill="black"
      />
      <path
        d="M96.1892 108.661H84.1892V120.661H96.1892V108.661Z"
        fill="black"
      />
      <path
        d="M96.1892 156.661H84.1892V168.661H96.1892V156.661Z"
        fill="black"
      />
      <path
        d="M108.189 24.6611H96.1892V36.6611H108.189V24.6611Z"
        fill="black"
      />
      <path
        d="M108.189 36.6611H96.1892V48.6611H108.189V36.6611Z"
        fill="black"
      />
      <path
        d="M108.189 72.6611H96.1892V84.6611H108.189V72.6611Z"
        fill="black"
      />
      <path
        d="M108.189 108.661H96.1892V120.661H108.189V108.661Z"
        fill="black"
      />
      <path
        d="M108.189 120.661H96.1892V132.661H108.189V120.661Z"
        fill="black"
      />
      <path
        d="M108.189 204.661H96.1892V216.661H108.189V204.661Z"
        fill="black"
      />
      <path
        d="M108.189 216.661H96.1892V228.661H108.189V216.661Z"
        fill="black"
      />
      <path
        d="M108.189 240.661H96.1892V252.661H108.189V240.661Z"
        fill="black"
      />
      <path
        d="M108.189 252.661H96.1892V264.661H108.189V252.661Z"
        fill="black"
      />
      <path
        d="M120.189 0.661133H108.189V12.6611H120.189V0.661133Z"
        fill="black"
      />
      <path
        d="M120.189 36.6611H108.189V48.6611H120.189V36.6611Z"
        fill="black"
      />
      <path
        d="M120.189 48.6611H108.189V60.6611H120.189V48.6611Z"
        fill="black"
      />
      <path
        d="M120.189 60.6611H108.189V72.6611H120.189V60.6611Z"
        fill="black"
      />
      <path
        d="M120.189 96.6611H108.189V108.661H120.189V96.6611Z"
        fill="black"
      />
      <path
        d="M120.189 132.661H108.189V144.661H120.189V132.661Z"
        fill="black"
      />
      <path
        d="M120.189 144.661H108.189V156.661H120.189V144.661Z"
        fill="black"
      />
      <path
        d="M120.189 156.661H108.189V168.661H120.189V156.661Z"
        fill="black"
      />
      <path
        d="M120.189 180.661H108.189V192.661H120.189V180.661Z"
        fill="black"
      />
      <path
        d="M120.189 204.661H108.189V216.661H120.189V204.661Z"
        fill="black"
      />
      <path
        d="M120.189 264.661H108.189V276.661H120.189V264.661Z"
        fill="black"
      />
      <path
        d="M120.189 276.661H108.189V288.661H120.189V276.661Z"
        fill="black"
      />
      <path
        d="M120.189 288.661H108.189V300.661H120.189V288.661Z"
        fill="black"
      />
      <path
        d="M132.189 24.6611H120.189V36.6611H132.189V24.6611Z"
        fill="black"
      />
      <path
        d="M132.189 60.6611H120.189V72.6611H132.189V60.6611Z"
        fill="black"
      />
      <path
        d="M132.189 72.6611H120.189V84.6611H132.189V72.6611Z"
        fill="black"
      />
      <path
        d="M132.189 84.6611H120.189V96.6611H132.189V84.6611Z"
        fill="black"
      />
      <path
        d="M132.189 96.6611H120.189V108.661H132.189V96.6611Z"
        fill="black"
      />
      <path
        d="M132.189 108.661H120.189V120.661H132.189V108.661Z"
        fill="black"
      />
      <path
        d="M132.189 120.661H120.189V132.661H132.189V120.661Z"
        fill="black"
      />
      <path
        d="M132.189 144.661H120.189V156.661H132.189V144.661Z"
        fill="black"
      />
      <path
        d="M132.189 180.661H120.189V192.661H132.189V180.661Z"
        fill="black"
      />
      <path
        d="M132.189 192.661H120.189V204.661H132.189V192.661Z"
        fill="black"
      />
      <path
        d="M132.189 216.661H120.189V228.661H132.189V216.661Z"
        fill="black"
      />
      <path
        d="M132.189 240.661H120.189V252.661H132.189V240.661Z"
        fill="black"
      />
      <path
        d="M132.189 264.661H120.189V276.661H132.189V264.661Z"
        fill="black"
      />
      <path
        d="M132.189 276.661H120.189V288.661H132.189V276.661Z"
        fill="black"
      />
      <path
        d="M132.189 288.661H120.189V300.661H132.189V288.661Z"
        fill="black"
      />
      <path
        d="M144.189 24.6611H132.189V36.6611H144.189V24.6611Z"
        fill="black"
      />
      <path
        d="M144.189 60.6611H132.189V72.6611H144.189V60.6611Z"
        fill="black"
      />
      <path
        d="M144.189 96.6611H132.189V108.661H144.189V96.6611Z"
        fill="black"
      />
      <path
        d="M144.189 108.661H132.189V120.661H144.189V108.661Z"
        fill="black"
      />
      <path
        d="M144.189 144.661H132.189V156.661H144.189V144.661Z"
        fill="black"
      />
      <path
        d="M144.189 192.661H132.189V204.661H144.189V192.661Z"
        fill="black"
      />
      <path
        d="M144.189 216.661H132.189V228.661H144.189V216.661Z"
        fill="black"
      />
      <path
        d="M144.189 228.661H132.189V240.661H144.189V228.661Z"
        fill="black"
      />
      <path
        d="M144.189 240.661H132.189V252.661H144.189V240.661Z"
        fill="black"
      />
      <path
        d="M144.189 252.661H132.189V264.661H144.189V252.661Z"
        fill="black"
      />
      <path
        d="M144.189 264.661H132.189V276.661H144.189V264.661Z"
        fill="black"
      />
      <path
        d="M144.189 288.661H132.189V300.661H144.189V288.661Z"
        fill="black"
      />
      <path
        d="M156.189 36.6611H144.189V48.6611H156.189V36.6611Z"
        fill="black"
      />
      <path
        d="M156.189 48.6611H144.189V60.6611H156.189V48.6611Z"
        fill="black"
      />
      <path
        d="M156.189 60.6611H144.189V72.6611H156.189V60.6611Z"
        fill="black"
      />
      <path
        d="M156.189 72.6611H144.189V84.6611H156.189V72.6611Z"
        fill="black"
      />
      <path
        d="M156.189 84.6611H144.189V96.6611H156.189V84.6611Z"
        fill="black"
      />
      <path
        d="M156.189 108.661H144.189V120.661H156.189V108.661Z"
        fill="black"
      />
      <path
        d="M156.189 132.661H144.189V144.661H156.189V132.661Z"
        fill="black"
      />
      <path
        d="M156.189 156.661H144.189V168.661H156.189V156.661Z"
        fill="black"
      />
      <path
        d="M156.189 192.661H144.189V204.661H156.189V192.661Z"
        fill="black"
      />
      <path
        d="M156.189 228.661H144.189V240.661H156.189V228.661Z"
        fill="black"
      />
      <path
        d="M156.189 252.661H144.189V264.661H156.189V252.661Z"
        fill="black"
      />
      <path
        d="M156.189 264.661H144.189V276.661H156.189V264.661Z"
        fill="black"
      />
      <path
        d="M156.189 276.661H144.189V288.661H156.189V276.661Z"
        fill="black"
      />
      <path
        d="M156.189 288.661H144.189V300.661H156.189V288.661Z"
        fill="black"
      />
      <path
        d="M168.189 12.6611H156.189V24.6611H168.189V12.6611Z"
        fill="black"
      />
      <path
        d="M168.189 60.6611H156.189V72.6611H168.189V60.6611Z"
        fill="black"
      />
      <path
        d="M168.189 108.661H156.189V120.661H168.189V108.661Z"
        fill="black"
      />
      <path
        d="M168.189 132.661H156.189V144.661H168.189V132.661Z"
        fill="black"
      />
      <path
        d="M168.189 228.661H156.189V240.661H168.189V228.661Z"
        fill="black"
      />
      <path
        d="M168.189 240.661H156.189V252.661H168.189V240.661Z"
        fill="black"
      />
      <path
        d="M168.189 264.661H156.189V276.661H168.189V264.661Z"
        fill="black"
      />
      <path
        d="M168.189 288.661H156.189V300.661H168.189V288.661Z"
        fill="black"
      />
      <path
        d="M180.189 24.6611H168.189V36.6611H180.189V24.6611Z"
        fill="black"
      />
      <path
        d="M180.189 48.6611H168.189V60.6611H180.189V48.6611Z"
        fill="black"
      />
      <path
        d="M180.189 60.6611H168.189V72.6611H180.189V60.6611Z"
        fill="black"
      />
      <path
        d="M180.189 72.6611H168.189V84.6611H180.189V72.6611Z"
        fill="black"
      />
      <path
        d="M180.189 108.661H168.189V120.661H180.189V108.661Z"
        fill="black"
      />
      <path
        d="M180.189 120.661H168.189V132.661H180.189V120.661Z"
        fill="black"
      />
      <path
        d="M180.189 132.661H168.189V144.661H180.189V132.661Z"
        fill="black"
      />
      <path
        d="M180.189 144.661H168.189V156.661H180.189V144.661Z"
        fill="black"
      />
      <path
        d="M180.189 168.661H168.189V180.661H180.189V168.661Z"
        fill="black"
      />
      <path
        d="M180.189 192.661H168.189V204.661H180.189V192.661Z"
        fill="black"
      />
      <path
        d="M180.189 204.661H168.189V216.661H180.189V204.661Z"
        fill="black"
      />
      <path
        d="M180.189 264.661H168.189V276.661H180.189V264.661Z"
        fill="black"
      />
      <path
        d="M192.189 24.6611H180.189V36.6611H192.189V24.6611Z"
        fill="black"
      />
      <path
        d="M192.189 60.6611H180.189V72.6611H192.189V60.6611Z"
        fill="black"
      />
      <path
        d="M192.189 96.6611H180.189V108.661H192.189V96.6611Z"
        fill="black"
      />
      <path
        d="M192.189 132.661H180.189V144.661H192.189V132.661Z"
        fill="black"
      />
      <path
        d="M192.189 168.661H180.189V180.661H192.189V168.661Z"
        fill="black"
      />
      <path
        d="M192.189 192.661H180.189V204.661H192.189V192.661Z"
        fill="black"
      />
      <path
        d="M192.189 216.661H180.189V228.661H192.189V216.661Z"
        fill="black"
      />
      <path
        d="M192.189 228.661H180.189V240.661H192.189V228.661Z"
        fill="black"
      />
      <path
        d="M192.189 288.661H180.189V300.661H192.189V288.661Z"
        fill="black"
      />
      <path
        d="M204.189 12.6611H192.189V24.6611H204.189V12.6611Z"
        fill="black"
      />
      <path
        d="M204.189 60.6611H192.189V72.6611H204.189V60.6611Z"
        fill="black"
      />
      <path
        d="M204.189 72.6611H192.189V84.6611H204.189V72.6611Z"
        fill="black"
      />
      <path
        d="M204.189 84.6611H192.189V96.6611H204.189V84.6611Z"
        fill="black"
      />
      <path
        d="M204.189 108.661H192.189V120.661H204.189V108.661Z"
        fill="black"
      />
      <path
        d="M204.189 120.661H192.189V132.661H204.189V120.661Z"
        fill="black"
      />
      <path
        d="M204.189 132.661H192.189V144.661H204.189V132.661Z"
        fill="black"
      />
      <path
        d="M204.189 180.661H192.189V192.661H204.189V180.661Z"
        fill="black"
      />
      <path
        d="M204.189 192.661H192.189V204.661H204.189V192.661Z"
        fill="black"
      />
      <path
        d="M204.189 204.661H192.189V216.661H204.189V204.661Z"
        fill="black"
      />
      <path
        d="M204.189 216.661H192.189V228.661H204.189V216.661Z"
        fill="black"
      />
      <path
        d="M204.189 228.661H192.189V240.661H204.189V228.661Z"
        fill="black"
      />
      <path
        d="M204.189 240.661H192.189V252.661H204.189V240.661Z"
        fill="black"
      />
      <path
        d="M204.189 264.661H192.189V276.661H204.189V264.661Z"
        fill="black"
      />
      <path
        d="M204.189 276.661H192.189V288.661H204.189V276.661Z"
        fill="black"
      />
      <path
        d="M204.189 288.661H192.189V300.661H204.189V288.661Z"
        fill="black"
      />
      <path
        d="M216.189 132.661H204.189V144.661H216.189V132.661Z"
        fill="black"
      />
      <path
        d="M216.189 180.661H204.189V192.661H216.189V180.661Z"
        fill="black"
      />
      <path
        d="M216.189 192.661H204.189V204.661H216.189V192.661Z"
        fill="black"
      />
      <path
        d="M216.189 240.661H204.189V252.661H216.189V240.661Z"
        fill="black"
      />
      <path
        d="M216.189 252.661H204.189V264.661H216.189V252.661Z"
        fill="black"
      />
      <path
        d="M216.189 264.661H204.189V276.661H216.189V264.661Z"
        fill="black"
      />
      <path
        d="M216.189 288.661H204.189V300.661H216.189V288.661Z"
        fill="black"
      />
      <path
        d="M228.189 0.661133H216.189V12.6611H228.189V0.661133Z"
        fill="black"
      />
      <path
        d="M228.189 12.6611H216.189V24.6611H228.189V12.6611Z"
        fill="black"
      />
      <path
        d="M228.189 24.6611H216.189V36.6611H228.189V24.6611Z"
        fill="black"
      />
      <path
        d="M228.189 36.6611H216.189V48.6611H228.189V36.6611Z"
        fill="black"
      />
      <path
        d="M228.189 48.6611H216.189V60.6611H228.189V48.6611Z"
        fill="black"
      />
      <path
        d="M228.189 60.6611H216.189V72.6611H228.189V60.6611Z"
        fill="black"
      />
      <path
        d="M228.189 72.6611H216.189V84.6611H228.189V72.6611Z"
        fill="black"
      />
      <path
        d="M228.189 144.661H216.189V156.661H228.189V144.661Z"
        fill="black"
      />
      <path
        d="M228.189 180.661H216.189V192.661H228.189V180.661Z"
        fill="black"
      />
      <path
        d="M228.189 192.661H216.189V204.661H228.189V192.661Z"
        fill="black"
      />
      <path
        d="M228.189 216.661H216.189V228.661H228.189V216.661Z"
        fill="black"
      />
      <path
        d="M228.189 240.661H216.189V252.661H228.189V240.661Z"
        fill="black"
      />
      <path
        d="M228.189 252.661H216.189V264.661H228.189V252.661Z"
        fill="black"
      />
      <path
        d="M228.189 276.661H216.189V288.661H228.189V276.661Z"
        fill="black"
      />
      <path
        d="M228.189 288.661H216.189V300.661H228.189V288.661Z"
        fill="black"
      />
      <path
        d="M240.189 0.661133H228.189V12.6611H240.189V0.661133Z"
        fill="black"
      />
      <path
        d="M240.189 72.6611H228.189V84.6611H240.189V72.6611Z"
        fill="black"
      />
      <path
        d="M240.189 120.661H228.189V132.661H240.189V120.661Z"
        fill="black"
      />
      <path
        d="M240.189 180.661H228.189V192.661H240.189V180.661Z"
        fill="black"
      />
      <path
        d="M240.189 192.661H228.189V204.661H240.189V192.661Z"
        fill="black"
      />
      <path
        d="M240.189 240.661H228.189V252.661H240.189V240.661Z"
        fill="black"
      />
      <path
        d="M240.189 276.661H228.189V288.661H240.189V276.661Z"
        fill="black"
      />
      <path
        d="M252.189 0.661133H240.189V12.6611H252.189V0.661133Z"
        fill="black"
      />
      <path
        d="M252.189 24.6611H240.189V36.6611H252.189V24.6611Z"
        fill="black"
      />
      <path
        d="M252.189 36.6611H240.189V48.6611H252.189V36.6611Z"
        fill="black"
      />
      <path
        d="M252.189 48.6611H240.189V60.6611H252.189V48.6611Z"
        fill="black"
      />
      <path
        d="M252.189 72.6611H240.189V84.6611H252.189V72.6611Z"
        fill="black"
      />
      <path
        d="M252.189 108.661H240.189V120.661H252.189V108.661Z"
        fill="black"
      />
      <path
        d="M252.189 156.661H240.189V168.661H252.189V156.661Z"
        fill="black"
      />
      <path
        d="M252.189 168.661H240.189V180.661H252.189V168.661Z"
        fill="black"
      />
      <path
        d="M252.189 192.661H240.189V204.661H252.189V192.661Z"
        fill="black"
      />
      <path
        d="M252.189 204.661H240.189V216.661H252.189V204.661Z"
        fill="black"
      />
      <path
        d="M252.189 216.661H240.189V228.661H252.189V216.661Z"
        fill="black"
      />
      <path
        d="M252.189 228.661H240.189V240.661H252.189V228.661Z"
        fill="black"
      />
      <path
        d="M252.189 240.661H240.189V252.661H252.189V240.661Z"
        fill="black"
      />
      <path
        d="M252.189 264.661H240.189V276.661H252.189V264.661Z"
        fill="black"
      />
      <path
        d="M252.189 276.661H240.189V288.661H252.189V276.661Z"
        fill="black"
      />
      <path
        d="M264.189 0.661133H252.189V12.6611H264.189V0.661133Z"
        fill="black"
      />
      <path
        d="M264.189 24.6611H252.189V36.6611H264.189V24.6611Z"
        fill="black"
      />
      <path
        d="M264.189 36.6611H252.189V48.6611H264.189V36.6611Z"
        fill="black"
      />
      <path
        d="M264.189 48.6611H252.189V60.6611H264.189V48.6611Z"
        fill="black"
      />
      <path
        d="M264.189 72.6611H252.189V84.6611H264.189V72.6611Z"
        fill="black"
      />
      <path
        d="M264.189 96.6611H252.189V108.661H264.189V96.6611Z"
        fill="black"
      />
      <path
        d="M264.189 108.661H252.189V120.661H264.189V108.661Z"
        fill="black"
      />
      <path
        d="M264.189 120.661H252.189V132.661H264.189V120.661Z"
        fill="black"
      />
      <path
        d="M264.189 132.661H252.189V144.661H264.189V132.661Z"
        fill="black"
      />
      <path
        d="M264.189 168.661H252.189V180.661H264.189V168.661Z"
        fill="black"
      />
      <path
        d="M264.189 180.661H252.189V192.661H264.189V180.661Z"
        fill="black"
      />
      <path
        d="M264.189 264.661H252.189V276.661H264.189V264.661Z"
        fill="black"
      />
      <path
        d="M264.189 288.661H252.189V300.661H264.189V288.661Z"
        fill="black"
      />
      <path
        d="M276.189 0.661133H264.189V12.6611H276.189V0.661133Z"
        fill="black"
      />
      <path
        d="M276.189 24.6611H264.189V36.6611H276.189V24.6611Z"
        fill="black"
      />
      <path
        d="M276.189 36.6611H264.189V48.6611H276.189V36.6611Z"
        fill="black"
      />
      <path
        d="M276.189 48.6611H264.189V60.6611H276.189V48.6611Z"
        fill="black"
      />
      <path
        d="M276.189 72.6611H264.189V84.6611H276.189V72.6611Z"
        fill="black"
      />
      <path
        d="M276.189 96.6611H264.189V108.661H276.189V96.6611Z"
        fill="black"
      />
      <path
        d="M276.189 108.661H264.189V120.661H276.189V108.661Z"
        fill="black"
      />
      <path
        d="M276.189 132.661H264.189V144.661H276.189V132.661Z"
        fill="black"
      />
      <path
        d="M276.189 168.661H264.189V180.661H276.189V168.661Z"
        fill="black"
      />
      <path
        d="M276.189 180.661H264.189V192.661H276.189V180.661Z"
        fill="black"
      />
      <path
        d="M276.189 192.661H264.189V204.661H276.189V192.661Z"
        fill="black"
      />
      <path
        d="M276.189 204.661H264.189V216.661H276.189V204.661Z"
        fill="black"
      />
      <path
        d="M276.189 264.661H264.189V276.661H276.189V264.661Z"
        fill="black"
      />
      <path
        d="M276.189 276.661H264.189V288.661H276.189V276.661Z"
        fill="black"
      />
      <path
        d="M288.189 0.661133H276.189V12.6611H288.189V0.661133Z"
        fill="black"
      />
      <path
        d="M288.189 72.6611H276.189V84.6611H288.189V72.6611Z"
        fill="black"
      />
      <path
        d="M288.189 108.661H276.189V120.661H288.189V108.661Z"
        fill="black"
      />
      <path
        d="M288.189 132.661H276.189V144.661H288.189V132.661Z"
        fill="black"
      />
      <path
        d="M288.189 156.661H276.189V168.661H288.189V156.661Z"
        fill="black"
      />
      <path
        d="M288.189 168.661H276.189V180.661H288.189V168.661Z"
        fill="black"
      />
      <path
        d="M288.189 192.661H276.189V204.661H288.189V192.661Z"
        fill="black"
      />
      <path
        d="M288.189 204.661H276.189V216.661H288.189V204.661Z"
        fill="black"
      />
      <path
        d="M288.189 240.661H276.189V252.661H288.189V240.661Z"
        fill="black"
      />
      <path
        d="M288.189 264.661H276.189V276.661H288.189V264.661Z"
        fill="black"
      />
      <path
        d="M288.189 276.661H276.189V288.661H288.189V276.661Z"
        fill="black"
      />
      <path
        d="M300.189 0.661133H288.189V12.6611H300.189V0.661133Z"
        fill="black"
      />
      <path
        d="M300.189 12.6611H288.189V24.6611H300.189V12.6611Z"
        fill="black"
      />
      <path
        d="M300.189 24.6611H288.189V36.6611H300.189V24.6611Z"
        fill="black"
      />
      <path
        d="M300.189 36.6611H288.189V48.6611H300.189V36.6611Z"
        fill="black"
      />
      <path
        d="M300.189 48.6611H288.189V60.6611H300.189V48.6611Z"
        fill="black"
      />
      <path
        d="M300.189 60.6611H288.189V72.6611H300.189V60.6611Z"
        fill="black"
      />
      <path
        d="M300.189 72.6611H288.189V84.6611H300.189V72.6611Z"
        fill="black"
      />
      <path
        d="M300.189 120.661H288.189V132.661H300.189V120.661Z"
        fill="black"
      />
      <path
        d="M300.189 132.661H288.189V144.661H300.189V132.661Z"
        fill="black"
      />
      <path
        d="M300.189 144.661H288.189V156.661H300.189V144.661Z"
        fill="black"
      />
      <path
        d="M300.189 168.661H288.189V180.661H300.189V168.661Z"
        fill="black"
      />
      <path
        d="M300.189 180.661H288.189V192.661H300.189V180.661Z"
        fill="black"
      />
      <path
        d="M300.189 216.661H288.189V228.661H300.189V216.661Z"
        fill="black"
      />
      <path
        d="M300.189 228.661H288.189V240.661H300.189V228.661Z"
        fill="black"
      />
      <path
        d="M300.189 252.661H288.189V264.661H300.189V252.661Z"
        fill="black"
      />
      <path
        d="M300.189 264.661H288.189V276.661H300.189V264.661Z"
        fill="black"
      />
      <path
        d="M300.189 276.661H288.189V288.661H300.189V276.661Z"
        fill="black"
      />
      <path
        d="M300.189 288.661H288.189V300.661H300.189V288.661Z"
        fill="black"
      />
      <rect x="107.188" y="96" width="85.6" height="109" fill="white" />
      <path
        d="M184.176 137.555L170.348 133.155C172.312 127.717 172.333 124.827 171.126 122.355C164.619 109.024 149.378 112.695 145.593 121.768C144.1 125.344 144.26 125.435 140.886 134.364C138.996 139.365 139.586 144.653 141.694 147.762C144.23 151.5 145.473 153.234 148.211 157.082C154.33 165.683 142.269 174.432 136.02 165.144C134.191 162.425 132.516 160.138 129.152 155.229C125.887 150.021 123.555 140.441 126.556 131.475C128.268 126.362 129.007 124.431 132.295 115.924C134.065 111.346 139.448 103.79 149.156 100.369C157.219 97.5271 167.771 98.2664 176.677 105.878C187.54 115.162 188.057 127.998 184.176 137.555Z"
        fill="#E33535"
      />
      <path
        d="M116.202 163.768L130.031 168.167C128.066 173.605 128.045 176.495 129.252 178.967C135.76 192.299 151.001 188.627 154.786 179.555C156.278 175.978 156.118 175.887 159.492 166.958C161.382 161.957 160.793 156.669 158.684 153.561C156.149 149.822 154.905 148.088 152.168 144.24C146.049 135.639 158.11 126.89 164.358 136.178C166.187 138.898 167.863 141.184 171.226 146.094C174.492 151.301 176.823 160.881 173.822 169.847C172.111 174.961 171.372 176.892 168.083 185.398C166.313 189.976 160.931 197.532 151.222 200.954C143.16 203.795 132.608 203.056 123.702 195.444C112.839 186.16 112.322 173.325 116.202 163.768Z"
        fill="#FFB696"
      />
    </svg>
  );
};

export default QRCodeDefault2;
