const QRCodeNoLogo = () => {
  return (
    <svg viewBox="0 0 394 394" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M187.64 0.439941H168.92V19.1599H187.64V0.439941Z"
        fill="currentColor"
      />
      <path
        d="M206.36 0.439941H187.64V19.1599H206.36V0.439941Z"
        fill="currentColor"
      />
      <path
        d="M243.8 0.439941H225.08V19.1599H243.8V0.439941Z"
        fill="currentColor"
      />
      <path
        d="M168.92 19.1602H150.2V37.8802H168.92V19.1602Z"
        fill="currentColor"
      />
      <path
        d="M206.36 19.1602H187.64V37.8802H206.36V19.1602Z"
        fill="currentColor"
      />
      <path
        d="M243.8 19.1602H225.08V37.8802H243.8V19.1602Z"
        fill="currentColor"
      />
      <path
        d="M187.64 37.8799H168.92V56.5999H187.64V37.8799Z"
        fill="currentColor"
      />
      <path
        d="M206.36 37.8799H187.64V56.5999H206.36V37.8799Z"
        fill="currentColor"
      />
      <path
        d="M243.8 37.8799H225.08V56.5999H243.8V37.8799Z"
        fill="currentColor"
      />
      <path
        d="M243.8 56.6001H225.08V75.3201H243.8V56.6001Z"
        fill="currentColor"
      />
      <path
        d="M168.92 75.3198H150.2V94.0398H168.92V75.3198Z"
        fill="currentColor"
      />
      <path
        d="M243.8 75.3198H225.08V94.0398H243.8V75.3198Z"
        fill="currentColor"
      />
      <path d="M187.64 94.04H168.92V112.76H187.64V94.04Z" fill="currentColor" />
      <path d="M206.36 94.04H187.64V112.76H206.36V94.04Z" fill="currentColor" />
      <path d="M243.8 94.04H225.08V112.76H243.8V94.04Z" fill="currentColor" />
      <path
        d="M168.92 112.76H150.2V131.48H168.92V112.76Z"
        fill="currentColor"
      />
      <path
        d="M206.36 112.76H187.64V131.48H206.36V112.76Z"
        fill="currentColor"
      />
      <path d="M243.8 112.76H225.08V131.48H243.8V112.76Z" fill="currentColor" />
      <path
        d="M187.64 131.48H168.92V150.2H187.64V131.48Z"
        fill="currentColor"
      />
      <path
        d="M206.36 131.48H187.64V150.2H206.36V131.48Z"
        fill="currentColor"
      />
      <path
        d="M225.08 131.48H206.36V150.2H225.08V131.48Z"
        fill="currentColor"
      />
      <path d="M243.8 131.48H225.08V150.2H243.8V131.48Z" fill="currentColor" />
      <path d="M19.16 150.2H0.440002V168.92H19.16V150.2Z" fill="currentColor" />
      <path d="M56.6 150.2H37.88V168.92H56.6V150.2Z" fill="currentColor" />
      <path d="M94.04 150.2H75.32V168.92H94.04V150.2Z" fill="currentColor" />
      <path d="M131.48 150.2H112.76V168.92H131.48V150.2Z" fill="currentColor" />
      <path d="M187.64 150.2H168.92V168.92H187.64V150.2Z" fill="currentColor" />
      <path d="M206.36 150.2H187.64V168.92H206.36V150.2Z" fill="currentColor" />
      <path d="M225.08 150.2H206.36V168.92H225.08V150.2Z" fill="currentColor" />
      <path d="M318.68 150.2H299.96V168.92H318.68V150.2Z" fill="currentColor" />
      <path d="M374.84 150.2H356.12V168.92H374.84V150.2Z" fill="currentColor" />
      <path
        d="M19.16 168.92H0.440002V187.64H19.16V168.92Z"
        fill="currentColor"
      />
      <path d="M56.6 168.92H37.88V187.64H56.6V168.92Z" fill="currentColor" />
      <path d="M75.32 168.92H56.6V187.64H75.32V168.92Z" fill="currentColor" />
      <path
        d="M112.76 168.92H94.04V187.64H112.76V168.92Z"
        fill="currentColor"
      />
      <path d="M150.2 168.92H131.48V187.64H150.2V168.92Z" fill="currentColor" />
      <path
        d="M187.64 168.92H168.92V187.64H187.64V168.92Z"
        fill="currentColor"
      />
      <path
        d="M225.08 168.92H206.36V187.64H225.08V168.92Z"
        fill="currentColor"
      />
      <path
        d="M281.24 168.92H262.52V187.64H281.24V168.92Z"
        fill="currentColor"
      />
      <path
        d="M299.96 168.92H281.24V187.64H299.96V168.92Z"
        fill="currentColor"
      />
      <path
        d="M318.68 168.92H299.96V187.64H318.68V168.92Z"
        fill="currentColor"
      />
      <path
        d="M393.56 168.92H374.84V187.64H393.56V168.92Z"
        fill="currentColor"
      />
      <path
        d="M19.16 187.64H0.440002V206.36H19.16V187.64Z"
        fill="currentColor"
      />
      <path d="M37.88 187.64H19.16V206.36H37.88V187.64Z" fill="currentColor" />
      <path d="M56.6 187.64H37.88V206.36H56.6V187.64Z" fill="currentColor" />
      <path d="M94.04 187.64H75.32V206.36H94.04V187.64Z" fill="currentColor" />
      <path
        d="M112.76 187.64H94.04V206.36H112.76V187.64Z"
        fill="currentColor"
      />
      <path
        d="M131.48 187.64H112.76V206.36H131.48V187.64Z"
        fill="currentColor"
      />
      <path
        d="M206.36 187.64H187.64V206.36H206.36V187.64Z"
        fill="currentColor"
      />
      <path d="M243.8 187.64H225.08V206.36H243.8V187.64Z" fill="currentColor" />
      <path
        d="M262.52 187.64H243.8V206.36H262.52V187.64Z"
        fill="currentColor"
      />
      <path
        d="M318.68 187.64H299.96V206.36H318.68V187.64Z"
        fill="currentColor"
      />
      <path
        d="M356.12 187.64H337.4V206.36H356.12V187.64Z"
        fill="currentColor"
      />
      <path
        d="M374.84 187.64H356.12V206.36H374.84V187.64Z"
        fill="currentColor"
      />
      <path
        d="M393.56 187.64H374.84V206.36H393.56V187.64Z"
        fill="currentColor"
      />
      <path d="M56.6 206.36H37.88V225.08H56.6V206.36Z" fill="currentColor" />
      <path d="M94.04 206.36H75.32V225.08H94.04V206.36Z" fill="currentColor" />
      <path
        d="M112.76 206.36H94.04V225.08H112.76V206.36Z"
        fill="currentColor"
      />
      <path d="M150.2 206.36H131.48V225.08H150.2V206.36Z" fill="currentColor" />
      <path
        d="M168.92 206.36H150.2V225.08H168.92V206.36Z"
        fill="currentColor"
      />
      <path
        d="M187.64 206.36H168.92V225.08H187.64V206.36Z"
        fill="currentColor"
      />
      <path d="M243.8 206.36H225.08V225.08H243.8V206.36Z" fill="currentColor" />
      <path
        d="M318.68 206.36H299.96V225.08H318.68V206.36Z"
        fill="currentColor"
      />
      <path
        d="M374.84 206.36H356.12V225.08H374.84V206.36Z"
        fill="currentColor"
      />
      <path
        d="M19.16 225.08H0.440002V243.8H19.16V225.08Z"
        fill="currentColor"
      />
      <path d="M37.88 225.08H19.16V243.8H37.88V225.08Z" fill="currentColor" />
      <path d="M75.32 225.08H56.6V243.8H75.32V225.08Z" fill="currentColor" />
      <path d="M94.04 225.08H75.32V243.8H94.04V225.08Z" fill="currentColor" />
      <path
        d="M131.48 225.08H112.76V243.8H131.48V225.08Z"
        fill="currentColor"
      />
      <path
        d="M225.08 225.08H206.36V243.8H225.08V225.08Z"
        fill="currentColor"
      />
      <path d="M262.52 225.08H243.8V243.8H262.52V225.08Z" fill="currentColor" />
      <path
        d="M299.96 225.08H281.24V243.8H299.96V225.08Z"
        fill="currentColor"
      />
      <path d="M337.4 225.08H318.68V243.8H337.4V225.08Z" fill="currentColor" />
      <path d="M168.92 243.8H150.2V262.52H168.92V243.8Z" fill="currentColor" />
      <path d="M206.36 243.8H187.64V262.52H206.36V243.8Z" fill="currentColor" />
      <path d="M225.08 243.8H206.36V262.52H225.08V243.8Z" fill="currentColor" />
      <path d="M243.8 243.8H225.08V262.52H243.8V243.8Z" fill="currentColor" />
      <path d="M262.52 243.8H243.8V262.52H262.52V243.8Z" fill="currentColor" />
      <path d="M299.96 243.8H281.24V262.52H299.96V243.8Z" fill="currentColor" />
      <path d="M318.68 243.8H299.96V262.52H318.68V243.8Z" fill="currentColor" />
      <path d="M374.84 243.8H356.12V262.52H374.84V243.8Z" fill="currentColor" />
      <path d="M393.56 243.8H374.84V262.52H393.56V243.8Z" fill="currentColor" />
      <path
        d="M206.36 262.52H187.64V281.24H206.36V262.52Z"
        fill="currentColor"
      />
      <path d="M243.8 262.52H225.08V281.24H243.8V262.52Z" fill="currentColor" />
      <path
        d="M262.52 262.52H243.8V281.24H262.52V262.52Z"
        fill="currentColor"
      />
      <path
        d="M318.68 262.52H299.96V281.24H318.68V262.52Z"
        fill="currentColor"
      />
      <path
        d="M356.12 262.52H337.4V281.24H356.12V262.52Z"
        fill="currentColor"
      />
      <path
        d="M374.84 262.52H356.12V281.24H374.84V262.52Z"
        fill="currentColor"
      />
      <path
        d="M393.56 262.52H374.84V281.24H393.56V262.52Z"
        fill="currentColor"
      />
      <path
        d="M225.08 281.24H206.36V299.96H225.08V281.24Z"
        fill="currentColor"
      />
      <path
        d="M262.52 281.24H243.8V299.96H262.52V281.24Z"
        fill="currentColor"
      />
      <path
        d="M299.96 281.24H281.24V299.96H299.96V281.24Z"
        fill="currentColor"
      />
      <path
        d="M318.68 281.24H299.96V299.96H318.68V281.24Z"
        fill="currentColor"
      />
      <path
        d="M168.92 299.96H150.2V318.68H168.92V299.96Z"
        fill="currentColor"
      />
      <path
        d="M225.08 299.96H206.36V318.68H225.08V299.96Z"
        fill="currentColor"
      />
      <path d="M243.8 299.96H225.08V318.68H243.8V299.96Z" fill="currentColor" />
      <path d="M337.4 299.96H318.68V318.68H337.4V299.96Z" fill="currentColor" />
      <path
        d="M374.84 299.96H356.12V318.68H374.84V299.96Z"
        fill="currentColor"
      />
      <path
        d="M206.36 318.68H187.64V337.4H206.36V318.68Z"
        fill="currentColor"
      />
      <path
        d="M225.08 318.68H206.36V337.4H225.08V318.68Z"
        fill="currentColor"
      />
      <path d="M243.8 318.68H225.08V337.4H243.8V318.68Z" fill="currentColor" />
      <path
        d="M281.24 318.68H262.52V337.4H281.24V318.68Z"
        fill="currentColor"
      />
      <path
        d="M318.68 318.68H299.96V337.4H318.68V318.68Z"
        fill="currentColor"
      />
      <path d="M337.4 318.68H318.68V337.4H337.4V318.68Z" fill="currentColor" />
      <path
        d="M374.84 318.68H356.12V337.4H374.84V318.68Z"
        fill="currentColor"
      />
      <path d="M168.92 337.4H150.2V356.12H168.92V337.4Z" fill="currentColor" />
      <path d="M187.64 337.4H168.92V356.12H187.64V337.4Z" fill="currentColor" />
      <path d="M206.36 337.4H187.64V356.12H206.36V337.4Z" fill="currentColor" />
      <path d="M225.08 337.4H206.36V356.12H225.08V337.4Z" fill="currentColor" />
      <path d="M243.8 337.4H225.08V356.12H243.8V337.4Z" fill="currentColor" />
      <path d="M318.68 337.4H299.96V356.12H318.68V337.4Z" fill="currentColor" />
      <path d="M356.12 337.4H337.4V356.12H356.12V337.4Z" fill="currentColor" />
      <path d="M393.56 337.4H374.84V356.12H393.56V337.4Z" fill="currentColor" />
      <path
        d="M206.36 356.12H187.64V374.84H206.36V356.12Z"
        fill="currentColor"
      />
      <path
        d="M318.68 356.12H299.96V374.84H318.68V356.12Z"
        fill="currentColor"
      />
      <path
        d="M374.84 356.12H356.12V374.84H374.84V356.12Z"
        fill="currentColor"
      />
      <path
        d="M168.92 374.84H150.2V393.56H168.92V374.84Z"
        fill="currentColor"
      />
      <path
        d="M187.64 374.84H168.92V393.56H187.64V374.84Z"
        fill="currentColor"
      />
      <path
        d="M206.36 374.84H187.64V393.56H206.36V374.84Z"
        fill="currentColor"
      />
      <path
        d="M318.68 374.84H299.96V393.56H318.68V374.84Z"
        fill="currentColor"
      />
      <path d="M337.4 374.84H318.68V393.56H337.4V374.84Z" fill="currentColor" />
      <path
        d="M374.84 374.84H356.12V393.56H374.84V374.84Z"
        fill="currentColor"
      />
      <path
        d="M393.56 374.84H374.84V393.56H393.56V374.84Z"
        fill="currentColor"
      />
      <path
        d="M111.824 0.439941H20.096H0.440002V20.0959V111.824V131.48H20.096H111.824H131.48V111.824V20.0959V0.439941H111.824ZM111.824 111.824H20.096V20.0959H111.824V111.824Z"
        fill="currentColor"
      />
      <path
        d="M373.904 0.439941H282.176H262.52V20.0959V111.824V131.48H282.176H373.904H393.56V111.824V20.0959V0.439941H373.904ZM373.904 111.824H282.176V20.0959H373.904V111.824Z"
        fill="currentColor"
      />
      <path
        d="M111.824 262.52H20.096H0.440002V282.176V373.904V393.56H20.096H111.824H131.48V373.904V282.176V262.52H111.824ZM111.824 373.904H20.096V282.176H111.824V373.904Z"
        fill="currentColor"
      />
      <path
        d="M94.04 37.8799H37.88V94.0399H94.04V37.8799Z"
        fill="currentColor"
      />
      <path
        d="M356.12 37.8799H299.96V94.0399H356.12V37.8799Z"
        fill="currentColor"
      />
      <path d="M94.04 299.96H37.88V356.12H94.04V299.96Z" fill="currentColor" />
    </svg>
  );
};

export default QRCodeNoLogo;
