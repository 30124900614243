/* eslint-disable no-useless-escape */
import emojiData from "@emoji-mart/data/sets/14/twitter.json";
import * as brandIcons from "@fortawesome/free-brands-svg-icons";
import * as icons from "@fortawesome/free-solid-svg-icons";
const exists: string[] = [];

type IconType = [key: string, value: any];

const iconLists = Object.entries({ ...icons, ...brandIcons })
  .filter(([, v]: IconType) => {
    const name = (v as any).iconName;
    if (name === null) return false;
    const isExist = exists.indexOf(name) === -1;
    if (isExist) exists.push(name);
    return isExist;
  })
  .map(([, v]: IconType) => ({ name: v.prefix + "-" + v.iconName, icon: v }))
  .filter((item) => item.name && item.name !== "undefined-undefined");

export const getIconByKey = (key) => {
  return iconLists.find((item) => item.name === key.replace(/^icon\:/, ""));
};

export const getEmojiByKey = (shortcodes: string) => {
  let id = "";
  let skin = "";
  const matches = shortcodes.match(/^(?:\:([^\:]+)\:)(?:\:skin-tone-(\d)\:)?$/);
  if (matches) {
    id = matches[1];

    if (matches[2]) {
      skin = matches[2];
    }
  }

  const emoji = emojiData.emojis[id];
  if (emoji) {
    return { emoji, skin };
  }
  return null;
};

export default iconLists;
