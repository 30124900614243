import clsx from "clsx";

const TabContainer = (props) => {
  return (
    <div
      {...props}
      className={clsx(
        "flex bg-gray-200 rounded-full text-sm p-1 font-semibold dark:bg-black dark:bg-opacity-40",
        props.className
      )}
    />
  );
};

const TabItem = ({ active, ...props }) => {
  return (
    <button
      {...props}
      className={clsx(
        "p-1 flex-1 font-semibold text-gray-500 transition-all duration-200",
        {
          "p-1 flex-1 bg-white shadow-md rounded-full text-gray-800 font-semibold dark:bg-gray-700 dark:text-white":
            active,
        }
      )}
    ></button>
  );
};

const Tab = {
  Container: TabContainer,
  Item: TabItem,
};

export default Tab;
