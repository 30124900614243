import { faEyedropper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@headlessui/react";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { usePopper } from "react-popper";

interface Props {
  value?: string | null;
  defaultColor?: string | null | false;
  disabled?: boolean;
  onChange(value: string);
}

const swatches = [
  "#54BAB9",
  "#9ED2C6",
  "#E9DAC1",
  "#850E35",
  "#DB4764",
  "#554994",
  "#F675A8",
  "#277BC0",
  "#FFB200",
  "#8728CA",
  "#6548C7",
  "#2D4494",
  "#3C4C8A",
  "#2898CF",
  "#16A795",
  "#16A74A",
  "#6DA80D",
  "#D4710C",
  "#D44E0C",
  "#CA1D13",
];

const ColorPicker = ({
  value,
  onChange,
  defaultColor = null,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const [curColor, setCurColor] = useState(value);
  const [refElement, setRefElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [eyedropperAvailable, setEyedropperAvailable] = useState(false);
  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement: "bottom-start",
  });

  const _onDefault = () => {
    if (disabled) return;
    if (defaultColor === false) {
    } else if (defaultColor || defaultColor === null) {
      onChange(defaultColor as string);
    }
  };

  useEffect(() => {
    setCurColor(value);
  }, [value]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (typeof (window as any).EyeDropper !== "undefined") {
        setEyedropperAvailable(true);
      }
    }
  }, []);

  const onGetDropper = () => {
    if (typeof window !== "undefined") {
      const eyeDropper = new (window as any).EyeDropper();
      eyeDropper
        .open()
        .then((result) => {
          setCurColor(result.sRGBHex);
          onChange(result.sRGBHex);
        })
        .catch(() => {
          //ok
        });
    }
  };

  return (
    <Popover className="relative">
      <div className="flex items-center">
        {/* @ts-ignore */}
        <Popover.Button ref={setRefElement} disabled={disabled}>
          <div
            className="w-8 h-8 rounded-md bg-black border dark:border-gray-900"
            style={{
              backgroundColor: `${value || "#fff"}`,
            }}
          />
        </Popover.Button>
        {eyedropperAvailable && (
          <button
            className="px-2 text-sm p-1 text-gray-600 dark:text-white -mr-2"
            onClick={onGetDropper}
          >
            <FontAwesomeIcon icon={faEyedropper} />
          </button>
        )}
        <button
          className="ml-3 text-sm underline text-gray-600 dark:text-gray-300"
          onClick={_onDefault}
        >
          {t("reset")}
        </button>
      </div>
      <Popover.Panel
        className="absolute my-1 z-50"
        //@ts-ignore
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className="dark:text-gray-900">
          <SketchPicker
            disableAlpha
            color={curColor || "#fff"}
            onChange={(color) => setCurColor(color.hex)}
            className="!shadow-lg !rounded-lg dark:text-gray-900"
            presetColors={swatches}
            onChangeComplete={(e) => {
              onChange(e.hex);
            }}
          />
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default ColorPicker;
