const QRCodeWithLogo = () => {
  return (
    <svg viewBox="0 0 404 404" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M145.517 0.275879H129.379V16.4138H145.517V0.275879Z"
        fill="currentColor"
      />
      <path
        d="M210.069 0.275879H193.931V16.4138H210.069V0.275879Z"
        fill="currentColor"
      />
      <path
        d="M226.207 0.275879H210.069V16.4138H226.207V0.275879Z"
        fill="currentColor"
      />
      <path
        d="M242.345 0.275879H226.207V16.4138H242.345V0.275879Z"
        fill="currentColor"
      />
      <path
        d="M161.655 16.4136H145.517V32.5515H161.655V16.4136Z"
        fill="currentColor"
      />
      <path
        d="M193.931 16.4136H177.793V32.5515H193.931V16.4136Z"
        fill="currentColor"
      />
      <path
        d="M210.069 16.4136H193.931V32.5515H210.069V16.4136Z"
        fill="currentColor"
      />
      <path
        d="M226.207 16.4136H210.069V32.5515H226.207V16.4136Z"
        fill="currentColor"
      />
      <path
        d="M242.345 16.4136H226.207V32.5515H242.345V16.4136Z"
        fill="currentColor"
      />
      <path
        d="M177.793 32.5518H161.655V48.6897H177.793V32.5518Z"
        fill="currentColor"
      />
      <path
        d="M242.345 32.5518H226.207V48.6897H242.345V32.5518Z"
        fill="currentColor"
      />
      <path
        d="M274.621 32.5518H258.483V48.6897H274.621V32.5518Z"
        fill="currentColor"
      />
      <path
        d="M145.517 48.6895H129.379V64.8274H145.517V48.6895Z"
        fill="currentColor"
      />
      <path
        d="M177.793 48.6895H161.655V64.8274H177.793V48.6895Z"
        fill="currentColor"
      />
      <path
        d="M193.931 48.6895H177.793V64.8274H193.931V48.6895Z"
        fill="currentColor"
      />
      <path
        d="M210.069 48.6895H193.931V64.8274H210.069V48.6895Z"
        fill="currentColor"
      />
      <path
        d="M226.207 48.6895H210.069V64.8274H226.207V48.6895Z"
        fill="currentColor"
      />
      <path
        d="M274.621 48.6895H258.483V64.8274H274.621V48.6895Z"
        fill="currentColor"
      />
      <path
        d="M242.345 64.8276H226.207V80.9656H242.345V64.8276Z"
        fill="currentColor"
      />
      <path
        d="M258.483 64.8276H242.345V80.9656H258.483V64.8276Z"
        fill="currentColor"
      />
      <path
        d="M274.621 64.8276H258.483V80.9656H274.621V64.8276Z"
        fill="currentColor"
      />
      <path
        d="M193.931 80.9653H177.793V97.1033H193.931V80.9653Z"
        fill="currentColor"
      />
      <path
        d="M226.207 80.9653H210.069V97.1033H226.207V80.9653Z"
        fill="currentColor"
      />
      <path
        d="M258.483 80.9653H242.345V97.1033H258.483V80.9653Z"
        fill="currentColor"
      />
      <path
        d="M274.621 80.9653H258.483V97.1033H274.621V80.9653Z"
        fill="currentColor"
      />
      <path
        d="M145.517 97.1035H129.379V113.241H145.517V97.1035Z"
        fill="currentColor"
      />
      <path
        d="M177.793 97.1035H161.655V113.241H177.793V97.1035Z"
        fill="currentColor"
      />
      <path
        d="M210.069 97.1035H193.931V113.241H210.069V97.1035Z"
        fill="currentColor"
      />
      <path
        d="M242.345 97.1035H226.207V113.241H242.345V97.1035Z"
        fill="currentColor"
      />
      <path
        d="M274.621 97.1035H258.483V113.241H274.621V97.1035Z"
        fill="currentColor"
      />
      <path
        d="M48.6897 129.379H32.5518V145.517H48.6897V129.379Z"
        fill="currentColor"
      />
      <path
        d="M80.9656 129.379H64.8276V145.517H80.9656V129.379Z"
        fill="currentColor"
      />
      <path
        d="M97.1035 129.379H80.9656V145.517H97.1035V129.379Z"
        fill="currentColor"
      />
      <path
        d="M113.241 129.379H97.1035V145.517H113.241V129.379Z"
        fill="currentColor"
      />
      <path
        d="M355.31 129.379H339.172V145.517H355.31V129.379Z"
        fill="currentColor"
      />
      <path
        d="M403.724 129.379H387.586V145.517H403.724V129.379Z"
        fill="currentColor"
      />
      <path
        d="M32.5518 145.517H16.4138V161.655H32.5518V145.517Z"
        fill="currentColor"
      />
      <path
        d="M64.8276 145.517H48.6897V161.655H64.8276V145.517Z"
        fill="currentColor"
      />
      <path
        d="M306.897 145.517H290.759V161.655H306.897V145.517Z"
        fill="currentColor"
      />
      <path
        d="M323.035 145.517H306.897V161.655H323.035V145.517Z"
        fill="currentColor"
      />
      <path
        d="M403.724 145.517H387.586V161.655H403.724V145.517Z"
        fill="currentColor"
      />
      <path
        d="M48.6897 161.655H32.5518V177.793H48.6897V161.655Z"
        fill="currentColor"
      />
      <path
        d="M97.1035 161.655H80.9656V177.793H97.1035V161.655Z"
        fill="currentColor"
      />
      <path
        d="M113.241 161.655H97.1035V177.793H113.241V161.655Z"
        fill="currentColor"
      />
      <path
        d="M323.035 161.655H306.897V177.793H323.035V161.655Z"
        fill="currentColor"
      />
      <path
        d="M371.448 161.655H355.31V177.793H371.448V161.655Z"
        fill="currentColor"
      />
      <path
        d="M387.586 161.655H371.448V177.793H387.586V161.655Z"
        fill="currentColor"
      />
      <path
        d="M403.724 161.655H387.586V177.793H403.724V161.655Z"
        fill="currentColor"
      />
      <path
        d="M64.8276 177.793H48.6897V193.931H64.8276V177.793Z"
        fill="currentColor"
      />
      <path
        d="M80.9656 177.793H64.8276V193.931H80.9656V177.793Z"
        fill="currentColor"
      />
      <path
        d="M306.897 177.793H290.759V193.931H306.897V177.793Z"
        fill="currentColor"
      />
      <path
        d="M323.035 177.793H306.897V193.931H323.035V177.793Z"
        fill="currentColor"
      />
      <path
        d="M387.586 177.793H371.448V193.931H387.586V177.793Z"
        fill="currentColor"
      />
      <path
        d="M16.4138 193.931H0.275879V210.069H16.4138V193.931Z"
        fill="currentColor"
      />
      <path
        d="M32.5518 193.931H16.4138V210.069H32.5518V193.931Z"
        fill="currentColor"
      />
      <path
        d="M48.6897 193.931H32.5518V210.069H48.6897V193.931Z"
        fill="currentColor"
      />
      <path
        d="M64.8276 193.931H48.6897V210.069H64.8276V193.931Z"
        fill="currentColor"
      />
      <path
        d="M80.9656 193.931H64.8276V210.069H80.9656V193.931Z"
        fill="currentColor"
      />
      <path
        d="M97.1035 193.931H80.9656V210.069H97.1035V193.931Z"
        fill="currentColor"
      />
      <path
        d="M113.241 193.931H97.1035V210.069H113.241V193.931Z"
        fill="currentColor"
      />
      <path
        d="M306.897 193.931H290.759V210.069H306.897V193.931Z"
        fill="currentColor"
      />
      <path
        d="M323.035 193.931H306.897V210.069H323.035V193.931Z"
        fill="currentColor"
      />
      <path
        d="M355.31 193.931H339.172V210.069H355.31V193.931Z"
        fill="currentColor"
      />
      <path
        d="M387.586 193.931H371.448V210.069H387.586V193.931Z"
        fill="currentColor"
      />
      <path
        d="M403.724 193.931H387.586V210.069H403.724V193.931Z"
        fill="currentColor"
      />
      <path
        d="M32.5518 210.069H16.4138V226.207H32.5518V210.069Z"
        fill="currentColor"
      />
      <path
        d="M48.6897 210.069H32.5518V226.207H48.6897V210.069Z"
        fill="currentColor"
      />
      <path
        d="M64.8276 210.069H48.6897V226.207H64.8276V210.069Z"
        fill="currentColor"
      />
      <path
        d="M80.9656 210.069H64.8276V226.207H80.9656V210.069Z"
        fill="currentColor"
      />
      <path
        d="M306.897 210.069H290.759V226.207H306.897V210.069Z"
        fill="currentColor"
      />
      <path
        d="M355.31 210.069H339.172V226.207H355.31V210.069Z"
        fill="currentColor"
      />
      <path
        d="M403.724 210.069H387.586V226.207H403.724V210.069Z"
        fill="currentColor"
      />
      <path
        d="M16.4138 226.207H0.275879V242.345H16.4138V226.207Z"
        fill="currentColor"
      />
      <path
        d="M113.241 226.207H97.1035V242.345H113.241V226.207Z"
        fill="currentColor"
      />
      <path
        d="M323.035 226.207H306.897V242.345H323.035V226.207Z"
        fill="currentColor"
      />
      <path
        d="M371.448 226.207H355.31V242.345H371.448V226.207Z"
        fill="currentColor"
      />
      <path
        d="M387.586 226.207H371.448V242.345H387.586V226.207Z"
        fill="currentColor"
      />
      <path
        d="M403.724 226.207H387.586V242.345H403.724V226.207Z"
        fill="currentColor"
      />
      <path
        d="M32.5518 242.345H16.4138V258.483H32.5518V242.345Z"
        fill="currentColor"
      />
      <path
        d="M339.172 242.345H323.034V258.483H339.172V242.345Z"
        fill="currentColor"
      />
      <path
        d="M387.586 242.345H371.448V258.483H387.586V242.345Z"
        fill="currentColor"
      />
      <path
        d="M16.4138 258.483H0.275879V274.621H16.4138V258.483Z"
        fill="currentColor"
      />
      <path
        d="M64.8276 258.483H48.6897V274.621H64.8276V258.483Z"
        fill="currentColor"
      />
      <path
        d="M80.9656 258.483H64.8276V274.621H80.9656V258.483Z"
        fill="currentColor"
      />
      <path
        d="M113.241 258.483H97.1035V274.621H113.241V258.483Z"
        fill="currentColor"
      />
      <path
        d="M306.897 258.483H290.759V274.621H306.897V258.483Z"
        fill="currentColor"
      />
      <path
        d="M323.035 258.483H306.897V274.621H323.035V258.483Z"
        fill="currentColor"
      />
      <path
        d="M339.172 258.483H323.034V274.621H339.172V258.483Z"
        fill="currentColor"
      />
      <path
        d="M355.31 258.483H339.172V274.621H355.31V258.483Z"
        fill="currentColor"
      />
      <path
        d="M339.172 274.621H323.034V290.759H339.172V274.621Z"
        fill="currentColor"
      />
      <path
        d="M355.31 274.621H339.172V290.759H355.31V274.621Z"
        fill="currentColor"
      />
      <path
        d="M387.586 274.621H371.448V290.759H387.586V274.621Z"
        fill="currentColor"
      />
      <path
        d="M403.724 274.621H387.586V290.759H403.724V274.621Z"
        fill="currentColor"
      />
      <path
        d="M177.793 290.759H161.655V306.897H177.793V290.759Z"
        fill="currentColor"
      />
      <path
        d="M210.069 290.759H193.931V306.897H210.069V290.759Z"
        fill="currentColor"
      />
      <path
        d="M226.207 290.759H210.069V306.897H226.207V290.759Z"
        fill="currentColor"
      />
      <path
        d="M242.345 290.759H226.207V306.897H242.345V290.759Z"
        fill="currentColor"
      />
      <path
        d="M274.621 290.759H258.483V306.897H274.621V290.759Z"
        fill="currentColor"
      />
      <path
        d="M306.897 290.759H290.759V306.897H306.897V290.759Z"
        fill="currentColor"
      />
      <path
        d="M339.172 290.759H323.034V306.897H339.172V290.759Z"
        fill="currentColor"
      />
      <path
        d="M355.31 290.759H339.172V306.897H355.31V290.759Z"
        fill="currentColor"
      />
      <path
        d="M387.586 290.759H371.448V306.897H387.586V290.759Z"
        fill="currentColor"
      />
      <path
        d="M403.724 290.759H387.586V306.897H403.724V290.759Z"
        fill="currentColor"
      />
      <path
        d="M145.517 306.896H129.379V323.034H145.517V306.896Z"
        fill="currentColor"
      />
      <path
        d="M177.793 306.896H161.655V323.034H177.793V306.896Z"
        fill="currentColor"
      />
      <path
        d="M193.931 306.896H177.793V323.034H193.931V306.896Z"
        fill="currentColor"
      />
      <path
        d="M210.069 306.896H193.931V323.034H210.069V306.896Z"
        fill="currentColor"
      />
      <path
        d="M242.345 306.896H226.207V323.034H242.345V306.896Z"
        fill="currentColor"
      />
      <path
        d="M258.483 306.896H242.345V323.034H258.483V306.896Z"
        fill="currentColor"
      />
      <path
        d="M274.621 306.896H258.483V323.034H274.621V306.896Z"
        fill="currentColor"
      />
      <path
        d="M339.172 306.896H323.034V323.034H339.172V306.896Z"
        fill="currentColor"
      />
      <path
        d="M355.31 306.896H339.172V323.034H355.31V306.896Z"
        fill="currentColor"
      />
      <path
        d="M145.517 323.035H129.379V339.173H145.517V323.035Z"
        fill="currentColor"
      />
      <path
        d="M210.069 323.035H193.931V339.173H210.069V323.035Z"
        fill="currentColor"
      />
      <path
        d="M242.345 323.035H226.207V339.173H242.345V323.035Z"
        fill="currentColor"
      />
      <path
        d="M274.621 323.035H258.483V339.173H274.621V323.035Z"
        fill="currentColor"
      />
      <path
        d="M290.759 323.035H274.621V339.173H290.759V323.035Z"
        fill="currentColor"
      />
      <path
        d="M306.897 323.035H290.759V339.173H306.897V323.035Z"
        fill="currentColor"
      />
      <path
        d="M323.035 323.035H306.897V339.173H323.035V323.035Z"
        fill="currentColor"
      />
      <path
        d="M339.172 323.035H323.034V339.173H339.172V323.035Z"
        fill="currentColor"
      />
      <path
        d="M355.31 323.035H339.172V339.173H355.31V323.035Z"
        fill="currentColor"
      />
      <path
        d="M387.586 323.035H371.448V339.173H387.586V323.035Z"
        fill="currentColor"
      />
      <path
        d="M193.931 339.172H177.793V355.31H193.931V339.172Z"
        fill="currentColor"
      />
      <path
        d="M210.069 339.172H193.931V355.31H210.069V339.172Z"
        fill="currentColor"
      />
      <path
        d="M242.345 339.172H226.207V355.31H242.345V339.172Z"
        fill="currentColor"
      />
      <path
        d="M274.621 339.172H258.483V355.31H274.621V339.172Z"
        fill="currentColor"
      />
      <path
        d="M323.035 339.172H306.897V355.31H323.035V339.172Z"
        fill="currentColor"
      />
      <path
        d="M339.172 339.172H323.034V355.31H339.172V339.172Z"
        fill="currentColor"
      />
      <path
        d="M355.31 339.172H339.172V355.31H355.31V339.172Z"
        fill="currentColor"
      />
      <path
        d="M371.448 339.172H355.31V355.31H371.448V339.172Z"
        fill="currentColor"
      />
      <path
        d="M387.586 339.172H371.448V355.31H387.586V339.172Z"
        fill="currentColor"
      />
      <path
        d="M145.517 355.311H129.379V371.448H145.517V355.311Z"
        fill="currentColor"
      />
      <path
        d="M161.655 355.311H145.517V371.448H161.655V355.311Z"
        fill="currentColor"
      />
      <path
        d="M193.931 355.311H177.793V371.448H193.931V355.311Z"
        fill="currentColor"
      />
      <path
        d="M210.069 355.311H193.931V371.448H210.069V355.311Z"
        fill="currentColor"
      />
      <path
        d="M226.207 355.311H210.069V371.448H226.207V355.311Z"
        fill="currentColor"
      />
      <path
        d="M242.345 355.311H226.207V371.448H242.345V355.311Z"
        fill="currentColor"
      />
      <path
        d="M258.483 355.311H242.345V371.448H258.483V355.311Z"
        fill="currentColor"
      />
      <path
        d="M339.172 355.311H323.034V371.448H339.172V355.311Z"
        fill="currentColor"
      />
      <path
        d="M403.724 355.311H387.586V371.448H403.724V355.311Z"
        fill="currentColor"
      />
      <path
        d="M161.655 371.448H145.517V387.586H161.655V371.448Z"
        fill="currentColor"
      />
      <path
        d="M177.793 371.448H161.655V387.586H177.793V371.448Z"
        fill="currentColor"
      />
      <path
        d="M242.345 371.448H226.207V387.586H242.345V371.448Z"
        fill="currentColor"
      />
      <path
        d="M258.483 371.448H242.345V387.586H258.483V371.448Z"
        fill="currentColor"
      />
      <path
        d="M274.621 371.448H258.483V387.586H274.621V371.448Z"
        fill="currentColor"
      />
      <path
        d="M339.172 371.448H323.034V387.586H339.172V371.448Z"
        fill="currentColor"
      />
      <path
        d="M355.31 371.448H339.172V387.586H355.31V371.448Z"
        fill="currentColor"
      />
      <path
        d="M387.586 371.448H371.448V387.586H387.586V371.448Z"
        fill="currentColor"
      />
      <path
        d="M193.931 387.586H177.793V403.724H193.931V387.586Z"
        fill="currentColor"
      />
      <path
        d="M210.069 387.586H193.931V403.724H210.069V387.586Z"
        fill="currentColor"
      />
      <path
        d="M274.621 387.586H258.483V403.724H274.621V387.586Z"
        fill="currentColor"
      />
      <path
        d="M306.897 387.586H290.759V403.724H306.897V387.586Z"
        fill="currentColor"
      />
      <path
        d="M323.035 387.586H306.897V403.724H323.035V387.586Z"
        fill="currentColor"
      />
      <path
        d="M387.586 387.586H371.448V403.724H387.586V387.586Z"
        fill="currentColor"
      />
      <path
        d="M403.724 387.586H387.586V403.724H403.724V387.586Z"
        fill="currentColor"
      />
      <path
        d="M96.2966 0.275879H17.2207H0.275879V17.2207V96.2966V113.241H17.2207H96.2966H113.241V96.2966V17.2207V0.275879H96.2966ZM96.2966 96.2966H17.2207V17.2207H96.2966V96.2966Z"
        fill="currentColor"
      />
      <path
        d="M386.779 0.275879H307.703H290.759V17.2207V96.2966V113.241H307.703H386.779H403.724V96.2966V17.2207V0.275879H386.779ZM386.779 96.2966H307.703V17.2207H386.779V96.2966Z"
        fill="currentColor"
      />
      <path
        d="M96.2966 290.759H17.2207H0.275879V307.704V386.779V403.724H17.2207H96.2966H113.241V386.779V307.704V290.759H96.2966ZM96.2966 386.779H17.2207V307.704H96.2966V386.779Z"
        fill="currentColor"
      />
      <path
        d="M80.9655 32.5518H32.5518V80.9655H80.9655V32.5518Z"
        fill="currentColor"
      />
      <path
        d="M371.448 32.5518H323.034V80.9655H371.448V32.5518Z"
        fill="currentColor"
      />
      <path
        d="M80.9655 323.035H32.5518V371.448H80.9655V323.035Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default QRCodeWithLogo;
