import {
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import ColorPicker from "@sid/core/components/ColorPicker";
import QRCodeDefault from "@sid/core/components/icon/QRCodeDefault";
import QRCodeNoLogo from "@sid/core/components/icon/QRCodeNoLogo";
import QRCodeWithLogo from "@sid/core/components/icon/QRCodeWithLogo";
import Spinner from "@sid/core/components/icon/Spinner";
import { useUserContext } from "@sid/core/context/UserContext";
import api, { handleAxioError } from "@sid/core/util/api";
import assetUrl from "@sid/core/vars/assetUrl";
import axios from "axios";
import clsx from "clsx";
import { Formik } from "formik";
import { Trans, useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { Fragment, useMemo, useState } from "react";
import toast from "react-hot-toast";
import useSWR from "swr";
import QRCodeDefault2 from "../icon/QRCodeDefault2";
import Button from "./Button";
import ImageChooser from "./ImageChooser";
import ModalComponent, { useModal } from "./Modal";
import SwitchButton from "./SwitchButton";

const QRRender = dynamic(() => import("@sid/core/components/QRRender"), {
  ssr: false,
});

const zeroObjectId = "000000000000000000000000";
const variant2ObjId = "000000000000000000000002";
const noLogoId = "000000000000000000000001";

interface QRCodeManagerProp {
  chooseOnly?: boolean;
  onChoose?();
  linkId?: any;
}

const QRCodeManager = ({
  chooseOnly = false,
  onChoose,
  linkId = null,
}: QRCodeManagerProp) => {
  const { t } = useTranslation();
  const [selItem, setSelItem] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const qrModal = useModal();
  const selectModal = useModal();
  const deleteModal = useModal();
  const { user, revalidate, subscription } = useUserContext();
  const qrData = useSWR(() => {
    if (chooseOnly) {
      return `/links/${linkId}/qrcode`;
    }
    return null;
  });

  const defaultQrCode = user?.user_preferences?.defaultQrCode;

  const { data, isValidating, mutate } = useSWR(() => {
    if (chooseOnly) {
      return "/user/qrcode?withDefault=1&page=1";
    }
    return "/user/qrcode";
  });

  const isCreateable = useMemo(() => {
    if (!subscription.config?.use_qrcode) return false;
    if (
      data &&
      subscription.config?.use_qrcode &&
      data.total < subscription.config?.max_qrcode
    ) {
      return true;
    }
    return false;
  }, [data, subscription]);

  const confirmSetDefault = async () => {
    setIsLoading(true);
    try {
      await api().put("/user/qrcode/default", { id: selItem.id });
      await revalidate();
      toast.success(t("success_save"));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(t(e.response?.data?.message));
      }
    }
    selectModal.setOpen(false);
    setIsLoading(false);
  };

  const preSetDefault = async (item) => {
    if (chooseOnly) {
      setIsLoading(true);
      try {
        await api().post(`/user/link/${linkId}/qrcode`, { id: item.id });
        await revalidate();
        toast.success(t("success_save"));
        //@ts-ignore
        onChoose();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          toast.error(t(e.response?.data?.message));
        }
      }
      setIsLoading(false);
    } else {
      selectModal.setOpen(true);
      setSelItem(item);
    }
    //...
  };

  const postDelete = async () => {
    setIsLoading(true);
    try {
      await api().delete("/user/qrcode/" + selItem.id);
      await mutate();
      toast.success(t("success_save"));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(t(e.response?.data?.message));
      }
    }
    deleteModal.setOpen(false);
    setIsLoading(false);
  };

  const preDelete = (item) => {
    deleteModal.setOpen(true);
    setSelItem(item);
    //...
  };

  const preEdit = (item) => {
    setSelItem(item);
    qrModal.setOpen(true);
  };

  const onSaveQRCode = async (values, { setErrors }) => {
    setIsLoading(true);
    try {
      if (!selItem) {
        await api().post("/user/qrcode", values);
      } else {
        await api().put("/user/qrcode", values);
      }
      await mutate();
      toast.success(t("success_save"));
      qrModal.setOpen(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        handleAxioError(e, { setErrors, t, toast, onError: null });
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-wrap -m-1 relative dark:text-black">
      {!data?.data && isValidating ? (
        [...new Array(3)].map((_, index) => (
          <div className="p-1 w-6/12 md:w-4/12 xl:w-[180px]" key={index}>
            <div className="pb-[100%] bg-white rounded-md border relative overflow-hidden">
              <div className="absolute top-2 left-2 right-2 bottom-2 bg-gray-200 rounded-md animate-pulse" />
              <div className="absolute w-8 h-8 right-4 bottom-4 bg-gray-300 rounded-md animate-pulse" />
            </div>
          </div>
        ))
      ) : (
        <>
          {(chooseOnly && defaultQrCode === zeroObjectId) || !chooseOnly ? (
            <QRItem
              slug="default"
              selected={
                chooseOnly
                  ? qrData.data?.data.id === zeroObjectId ||
                    (!qrData.data?.data.id && defaultQrCode === zeroObjectId)
                  : defaultQrCode === zeroObjectId
              }
              onSelect={() => preSetDefault({ id: "" })}
              isDefault={defaultQrCode === zeroObjectId}
            />
          ) : (
            <Fragment />
          )}

          {(chooseOnly && defaultQrCode === variant2ObjId) || !chooseOnly ? (
            <QRItem
              slug="default-2"
              selected={
                chooseOnly
                  ? qrData.data?.data.id === variant2ObjId ||
                    (!qrData.data?.data.id && defaultQrCode === variant2ObjId)
                  : defaultQrCode === variant2ObjId
              }
              onSelect={() => preSetDefault({ id: variant2ObjId })}
              isDefault={defaultQrCode === variant2ObjId}
            />
          ) : (
            <Fragment />
          )}

          <QRItem
            slug="nologo"
            disabled={!subscription.config.use_qrcode}
            selected={
              chooseOnly
                ? qrData.data?.data.id === noLogoId ||
                  (!qrData.data?.data.id && defaultQrCode === noLogoId)
                : defaultQrCode === noLogoId
            }
            onSelect={() => {
              if (chooseOnly && defaultQrCode === noLogoId) {
                preSetDefault({ id: "" });
              } else {
                preSetDefault({ id: noLogoId });
              }
            }}
            isDefault={defaultQrCode === noLogoId}
          />
          {data?.data.map((item, index) => {
            let selected = defaultQrCode === item.id;

            if (chooseOnly) {
              selected = qrData.data?.data.id
                ? item.id === qrData.data?.data.id
                : selected;
            }

            return (
              <QRItem
                key={index}
                slug={"123"}
                logo={item.logo}
                custom
                active={item.active}
                selected={selected}
                color={item.color || "#000000"}
                onDelete={() => preDelete(item)}
                onSelect={() => {
                  if (chooseOnly && defaultQrCode === item.id) {
                    preSetDefault({ id: "" });
                  } else {
                    preSetDefault(item);
                  }
                }}
                onEdit={() => preEdit(item)}
                chooseOnly={chooseOnly}
                isDefault={defaultQrCode === item.id}
              />
            );
          })}
          {!chooseOnly && (
            <QRItem
              isNew
              disabled={!isCreateable}
              onClickNew={() => {
                setSelItem(null);
                qrModal.setOpen(true);
              }}
            />
          )}

          <div className="w-full p-2 hidden">
            <Button className="w-full" color="primary">
              <Spinner className="w-5 mr-2 -mt-1 animate-spin hidden" />
              Load more
            </Button>
          </div>
        </>
      )}

      {chooseOnly && isLoading ? (
        <div className="absolute top-0 left-0 w-full h-full bg-white dark:bg-gray-800 dark:bg-opacity-75 z-10 bg-opacity-75 flex justify-center items-center">
          <Spinner className="w-12 animate-spin" />
        </div>
      ) : (
        <Fragment />
      )}

      <ModalComponent {...qrModal} disabled className="!max-w-[650px]">
        <div className="flex">
          <h1 className="text-xl font-semibold font-montserrat flex-1">
            Modify QR Code
          </h1>
          <button onClick={qrModal.close}>
            <XMarkIcon className="w-7" />
          </button>
        </div>

        <Formik
          initialValues={
            selItem
              ? selItem
              : {
                  active: true,
                  logo: "",
                  color: "#000000",
                }
          }
          onSubmit={onSaveQRCode}
        >
          {({ values, isSubmitting, submitForm, setFieldValue }) => (
            <div className="flex items-center flex-col md:flex-row">
              <div className="max-w-[200px]">
                <QRRender
                  url="https://s.id/sid"
                  data={values}
                  isLoading={false}
                />
              </div>
              <div className="w-full md:pl-6 mt-6 md:mt-0">
                <div className="w-full border-b dark:border-b-gray-600">
                  <h2 className="font-montserrat font-semibold">
                    Customize QR Code
                  </h2>
                </div>
                <div className="w-full py-3 pt-1 flex flex-wrap relative">
                  <div className="w-full mb-3">
                    <p className="text-sm">
                      <Trans i18nKey="dashboard:chaging_logo_change_all_qr">
                        Changing the Logo and color will also change the
                        appearance of your Link&apos;s QR Code.
                      </Trans>
                    </p>
                  </div>
                  <div className="w-6/12">
                    <label className="font-bold mb-1 block">
                      <Trans i18nKey="logo">Logo</Trans>
                    </label>
                    <div className="w-28 text-sm overflow-hidden rounded-lg text-center">
                      <ImageChooser
                        value={values.logo}
                        small
                        square
                        withCrop
                        maxWidth={500}
                        uploadPath={`/user/qrcode/upload`}
                        onChange={(image) => {
                          setFieldValue("logo", image);
                        }}
                        id="qr-image-chooser"
                      />
                    </div>
                    <div className="mt-2 flex items-center">
                      <SwitchButton
                        open={values.active}
                        onToggle={(value) => {
                          setFieldValue("active", value);
                        }}
                      />
                      <span className="ml-2">
                        <Trans i18nKey="active">Active</Trans>
                      </span>
                    </div>
                  </div>
                  <div className="w-6/12 flex flex-col h-full">
                    <div className="h-full">
                      <label className="font-bold mb-1 block">
                        <Trans i18nKey="dashboard:color">Color</Trans>
                      </label>
                      <ColorPicker
                        onChange={(value) => {
                          setFieldValue("color", value);
                        }}
                        defaultColor="#000000"
                        value={values.color}
                      />
                    </div>
                  </div>
                  <div className="absolute bottom-0 right-0">
                    <Button
                      onClick={submitForm}
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <Spinner className="w-5 inline-block -mt-1 mr-2 animate-spin" />
                      )}
                      <Trans i18nKey="save">Save</Trans>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </ModalComponent>

      <ModalComponent {...selectModal} disabled={isLoading}>
        <h1 className="text-xl font-semibold font-montserrat mb-2">
          <Trans i18nKey="dashboard:confirm_qrcode_default_heading">
            Confirm default QR Code
          </Trans>
        </h1>
        <p>
          <Trans i18nKey="dashboard:confirm_qrcode_default_desc">
            Confirm this QR Style become default of all your QR Code?
          </Trans>
        </p>
        <div className="flex justify-end gap-2 mt-4">
          <Button
            color="secondary"
            onClick={selectModal.close}
            disabled={isLoading}
          >
            <Trans i18nKey="cancel">Cancel</Trans>
          </Button>
          <Button
            onClick={confirmSetDefault}
            color="primary"
            disabled={isLoading}
          >
            {isLoading && (
              <Spinner className="w-5 inline-block animate-spin mr-2 -mt-1" />
            )}
            <Trans i18nKey="confirm">Confirm</Trans>
          </Button>
        </div>
      </ModalComponent>

      <ModalComponent {...deleteModal} disabled={isLoading}>
        <h1 className="text-xl font-semibold font-montserrat mb-2">
          <Trans i18nKey="dashboard:confirm_qrcode_delete_heading">
            Confirm delete
          </Trans>
        </h1>
        <p>
          <Trans i18nKey="dashboard:confirm_qrcode_delete_desc">
            Confirm delete this QR Design?
          </Trans>
        </p>
        <div className="flex justify-end gap-2 mt-4">
          <Button
            color="secondary"
            onClick={deleteModal.close}
            disabled={isLoading}
          >
            <Trans i18nKey="cancel">Cancel</Trans>
          </Button>
          <Button onClick={postDelete} color="primary" disabled={isLoading}>
            {isLoading && (
              <Spinner className="w-5 inline-block animate-spin mr-2 -mt-1" />
            )}
            <Trans i18nKey="delete">Delete</Trans>
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
};

const QRItem = ({
  isNew = false,
  slug = "",
  active = false,
  selected = false,
  custom = false,
  logo = "",
  color = "",
  onClickNew,
  onSelect,
  onEdit,
  onDelete,
  disabled = false,
  chooseOnly = false,
  isDefault = false,
}: any) => {
  return (
    <div className="p-1 w-6/12 md:w-4/12 xl:w-[180px]">
      <div
        className={clsx(
          "pb-[100%] bg-white rounded-md border relative overflow-hidden",
          disabled && "opacity-40",
          isNew && "dark:bg-gray-900 dark:text-white dark:border-gray-800"
        )}
      >
        {isNew ? (
          <button
            disabled={disabled}
            className={clsx(
              "absolute w-full h-full flex items-center justify-center flex-col"
            )}
            onClick={onClickNew}
          >
            <PlusIcon className="w-6" />
            <span>Create new</span>
          </button>
        ) : (
          <>
            <div className="absolute right-0 text-xs font-semibold z-10">
              {(isDefault && selected) || isDefault ? (
                <div className="bg-blue-500 text-white px-2 py-0.5 mb-1">
                  <Trans i18nKey="selected">Default</Trans>
                </div>
              ) : selected ? (
                <div className="bg-orange-500 text-white px-2 py-0.5">
                  <Trans i18nKey="selected">Selected</Trans>
                </div>
              ) : (
                <Fragment />
              )}
            </div>
            <button
              className="absolute w-full h-full p-2"
              style={{ color }}
              disabled={disabled || selected}
              onClick={onSelect}
            >
              {slug === "default" ? (
                <QRCodeDefault />
              ) : slug === "default-2" ? (
                <QRCodeDefault2 />
              ) : slug === "nologo" || !active ? (
                <QRCodeNoLogo />
              ) : active ? (
                <>
                  <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center z-10">
                    <div
                      className="w-[33%] pb-[33%] bg-center bg-contain bg-no-repeat"
                      style={{
                        backgroundImage: `url(${assetUrl + logo})`,
                      }}
                    ></div>
                  </div>
                  {logo === "" ? <QRCodeDefault /> : <QRCodeWithLogo />}
                </>
              ) : !active && logo?.length > 0 ? (
                <QRCodeNoLogo />
              ) : (
                <QRCodeDefault />
              )}
            </button>

            <div className="absolute bottom-0 pb-5 pt-16 w-full flex justify-center from-transparent to-white bg-gradient-to-b pointer-events-none">
              <div className="bg-black text-white px-2 rounded-md text-xs font-semibold">
                {slug === "default" || slug === "default-2" ? "s.id" : ""}
                {slug === "nologo" && "No Logo"}
              </div>
            </div>
            {custom && !chooseOnly ? (
              <div className="absolute bottom-2 right-2 z-10">
                <button
                  className="bg-black bg-opacity-70 text-white rounded p-1 mr-2 transition-all hover:bg-opacity-100"
                  onClick={onEdit}
                >
                  <PencilIcon className="w-5" />
                </button>
                <button
                  className="bg-black bg-opacity-70 text-white rounded p-1 transition-all hover:bg-opacity-100"
                  onClick={onDelete}
                >
                  <TrashIcon className="w-5" />
                </button>
              </div>
            ) : (
              <Fragment />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QRCodeManager;
