const MAX_WIDTH = 980;
const MAX_HEIGHT = 980;
import imageCompression from "browser-image-compression";
import { Crop } from "react-image-crop";

const resizeBeforeUpload = (
  imageFile: File | Blob,
  isCropping = false,
  scaleWidth = 1,
  scaleHeight = 1,
  crop: Crop,
  maxWidth = MAX_WIDTH,
  maxHeight = MAX_HEIGHT
) => {
  return new Promise<Blob>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement("img");
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        let isResize = false;

        if (imageFile.size / 1024 > 1024) {
          isResize = true;
        }

        // if (width > height) {
        //   if (width > maxWidth) {
        //     height = height * (maxWidth / width);
        //     width = maxWidth;
        //     isResize = true;
        //   }
        // } else {
        //   if (height > maxHeight) {
        //     width = width * (maxHeight / height);
        //     height = maxHeight;
        //     isResize = true;
        //   }
        // }

        if (isResize || isCropping) {
          const canvas = document.createElement("canvas");
          if (isCropping) {
            canvas.width = crop.width;
            canvas.height = crop.height;
          } else {
            canvas.width = width;
            canvas.height = height;
          }
          const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
          ctx.imageSmoothingEnabled = true;

          if (isCropping) {
            const scaleX = img.naturalWidth / scaleWidth;
            const scaleY = img.naturalHeight / scaleHeight;
            const pixelRatio = window.devicePixelRatio;
            canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
            canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
            ctx.scale(pixelRatio, pixelRatio);
            const cropX = crop.x * scaleX;
            const cropY = crop.y * scaleY;
            const centerX = img.naturalWidth / 2;
            const centerY = img.naturalHeight / 2;
            ctx.save();
            ctx.translate(-cropX, -cropY);
            ctx.translate(centerX, centerY);
            ctx.scale(1, 1);
            ctx.translate(-centerX, -centerY);
            ctx.drawImage(
              img,
              0,
              0,
              img.naturalWidth,
              img.naturalHeight,
              0,
              0,
              img.naturalWidth,
              img.naturalHeight
            );
            ctx.restore();
          } else {
            ctx?.drawImage(img, 0, 0, width, height);
          }

          canvas.toBlob(async (blob) => {
            if (blob) {
              const out = await imageCompression(blob as any, {
                maxSizeMB: 0.9,
                maxWidthOrHeight: maxWidth,
                useWebWorker: true,
              });

              // const newImg = document.createElement("img");
              // const url = URL.createObjectURL(out as any);
              // newImg.onload = function () {
              //   // no longer need to read the blob so it's revoked
              //   URL.revokeObjectURL(url);
              // };
              // window.open(url, "_blank");

              // reject("failed");

              resolve(out);
            } else {
              reject("failed");
            }

            img.remove();
            canvas.remove();
          }, imageFile.type);
        } else {
          resolve(imageFile);
          img.remove();
        }
      };
      img.src = e.target?.result as string;
    };
    reader.readAsDataURL(imageFile);
  });
};

export default resizeBeforeUpload;
